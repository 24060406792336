import React, { useRef } from 'react';
import Icon from '../icon';
import { roomStore } from '../../stores/room';
import { whiteboard } from '../../stores/whiteboard';
import moment from 'moment';
import { globalStore } from '../../stores/global';
import { getOSSUrl } from '../../utils/helper';
import { t } from '../../i18n';
import {ErpApi} from '../../utils/erpApi';
import GlobalStorage from '../../utils/custom-storage';
import { useGlobalState } from '../../containers/root-container';

interface ControlItemProps {
  index: number
  name: string
  onClick: (evt: any, name: string, index: number) => void
  active: boolean
  text?: string
  value?:string
}

const ControlItem = (props: ControlItemProps) => {
  const globalState = useGlobalState();
  const onClick = (evt: any) => {
    if(props.name != "peer_hands_up" || !globalState.isApplying) {
      props.onClick(evt, props.name, props.index);
    }
  }
  return (
    props.text ?
      <div className={`control-btn control-${props.name}`} onClick={onClick}>
        <div className={`btn-icon ${props.name} ${props.active ? 'active' : ''}`}
          data-name={props.name} />
        <div className="control-text">{props.text}</div>
      </div>
      :
      <Icon
          applyIndex={props.index}
          applyValue={props.value}
        data={props.name}
        onClick={onClick}
        className={`items ${props.name} ${props.active ? 'active' : ''}`}
      />
  )
}

interface NoticeProps {
  reason: string
  text?: string
  name: string
  userId:string
}

interface ControlProps {
  sharing: boolean
  isHost?: boolean
  current: string
  currentPage: number
  totalPage: number
  role: string
  // notice?: NoticeProps
  noticeList: NoticeProps[]
  onClick: (evt: any, type: string,index: number) => void
}

export default function Control({
  sharing,
  isHost,
  current,
  currentPage,
  totalPage,
  onClick,
  role,
  noticeList,
}: ControlProps) {
  const lock = useRef<boolean>(false);

  const canStop = () => {
    const timeMoment = moment(whiteboard.state.startTime).add(15, 'seconds');
    if (+timeMoment >= +Date.now()) {
      globalStore.showToast({
        type: 'netlessClient',
        message: t('toast.recording_too_short')
      })
      return false;
    }
    return true;
  }

  const onRecordButtonClick = (evt: any, type: string) => {
    handleRecording(evt, type)
    .then(() => {}).catch(console.warn);
  }
  
  const handleRecording = async (evt: any, type: string) => {
    try {
      const roomState = roomStore.state;
      const me = roomState.me;
      if (lock.current || !me.uid) return;
  
      if (whiteboard.state.recording) {
        if (!canStop()) return;
        let mediaUrl = await whiteboard.stopRecording();
        globalStore.showToast({
          type: 'recording',
          message: t('toast.stop_recording'),
        });
        if (whiteboard.state.endTime 
          && whiteboard.state.startTime) {
          const {endTime, startTime, roomUUID} = whiteboard.clearRecording();
          // await roomStore.rtmClient.sendChannelMessage(JSON.stringify({
          //   account: me.account,
          //   url: getOSSUrl(mediaUrl),
          //   link: `/replay/${roomUUID}/${startTime}/${endTime}/${mediaUrl}`
          // }));
          // const message = {
          //   account: me.account,
          //   id: me.uid,
          //   link: `/replay/${roomUUID}/${startTime}/${endTime}/${mediaUrl}`,
          //   text: '',
          //   ts: +Date.now()
          // }
          // roomStore.updateChannelMessage(message);

          console.log("mediaUrl>>>",mediaUrl)
          let userInfo = GlobalStorage.getUserInfo().userInfo;
          let resultData = ErpApi.saveVideo(userInfo.banId,`https://online.doyee.com/#/replay/${roomUUID}/${startTime}/${endTime}/${mediaUrl}`);
          resultData.then(function(data){
            if(data.status == 0) {
              globalStore.showToast({
                type: 'notice-board',
                message: "上传成功"
              });
            }else{
              globalStore.showToast({
                type: 'notice-board',
                message: data.message
              });
            }
          });
          return;
        }
      } else {
        await whiteboard.startRecording();
        globalStore.showToast({
          type: 'recording',
          message: t('toast.start_recording'),
        });
      }
    } catch(err) {
      if (err.recordingErr) {
        globalStore.showToast({
          type: 'recordingErr',
          message: t('toast.recording_failed', {reason: err.recordingErr.message})
        });
      }
    }
  }

  return (
    <div className="controls-container">
      <div className="interactive">
        {noticeList.length>0 ?
            noticeList.map((item: any, key: number) => (
                <ControlItem name={item.reason}
                             value={item.text}
                             index={key}
                             onClick={onClick}
                             active={item.reason === current} />
            ))
        : null}
      </div>
      <div className="controls">
        {!sharing && role === 'teacher' ?
          <>
            <ControlItem name={`first_page`}
                         index={-1}
              active={'first_page' === current}
              onClick={onClick} />
            <ControlItem name={`prev_page`}
                         index={-1}
              active={'prev_page' === current}
              onClick={onClick} />
            <div className="current_page">
              <span>{currentPage}/{totalPage}</span>
            </div>
            <ControlItem name={`next_page`}
                         index={-1}
              active={'next_page' === current}
              onClick={onClick} />
            <ControlItem name={`last_page`}
                         index={-1}
              active={'last_page' === current}
              onClick={onClick} />
            <div className="menu-split" style={{ marginLeft: '7px', marginRight: '7px' }}></div>
          </> : null
        }
        {role === 'teacher' ?
            <>
            <ControlItem
                name={whiteboard.state.recording ? 'stop_recording' : 'recording'}
                index={-1}
                onClick={onRecordButtonClick}
                active={false}
            />
            <ControlItem
                name={sharing ? 'quit_screen_sharing' : 'screen_sharing'}
                index={-1}
                onClick={onClick}
                active={false}
                text={sharing ? 'stop sharing' : ''}
            />
            </> : null }
        {role === 'student' ?
          <>
            <ControlItem
                index={-1}
              name={isHost ? 'hands_up_end' : 'hands_up'}
              onClick={onClick}
              active={false}
              text={''}
            />
          </>
         :null}
      </div>

    </div>
  )
}