import { roomStore } from './../stores/room';
import {useEffect, useRef, useMemo} from 'react';

export default function useChatControl () {

  const lock = useRef<boolean>(false);

  useEffect(() => {
    lock.current = false;
    return () => {
      lock.current = true;
    }
  }, []);

  const me = roomStore.state.me;
  const course = roomStore.state.course;

  const muteControl = me.role === 'teacher';

  const muteChat = Boolean(course.muteChat);
  const muteAudio = Boolean(course.muteAudio);//newAudio

  const chat =  Boolean(me.chat);

  const disableChat: boolean = useMemo(() => {
    if (me.role === 'student' && (muteChat || !chat)) return true;
    return false;
  }, [muteChat, chat, me.role]);

  const disableAudio: boolean = useMemo(() => {
    console.log(123123444)
    if (me.role === 'student'){
      if (muteAudio) {
        console.log("handleClick")
        roomStore.unmute(me.uid, "audio")
            .then(() => {
            }).catch(console.warn)
            .finally(() => {
              // lock.current = false;
            });
      } else {
        roomStore.mute(me.uid, "audio")
            .then(() => {
            }).catch(console.warn)
            .finally(() => {
              // lock.current = false;
            });
      }
    }
    return false;
  }, [muteAudio, chat, me.role]);

  return {
    chat,
    disableChat,
    disableAudio,
    muteControl,
    muteChat,
    muteAudio,//newAudio
    handleMute (type: string) {
      if (!lock.current) {
        lock.current = true;
        roomStore.updateMe({
          muteChat: type === 'mute' ? 1 : 0
        }).then(() => {
          console.log("update success");
        }).catch(console.warn)
        .finally(() => {
          lock.current = false;
        })
      }

    },
    handleMuteAudio (type: string) {
      if (!lock.current) {
        lock.current = true;
        roomStore.updateMe({
          muteAudio: type === 'mute' ? 1 : 0
        }).then(() => {
          console.log("update success");
        }).catch(console.warn)
            .finally(() => {
              lock.current = false;
            })
      }

    },
  }
}