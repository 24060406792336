import React, { useRef, useEffect, useState } from 'react';
import VideoPlayer from '../../components/video-player';
import MediaBoard from '../../components/mediaboard';
import ChatBoard from '../../components/chat/board';
import useStream from '../../hooks/use-streams';
import useChatText from '../../hooks/use-chat-text';
import EZUIKit from './ezuikit.js'

import {ErpApi} from '../../utils/erpApi'
import GlobalStorage from "../../utils/custom-storage";
import {whiteboard} from "../../stores/whiteboard";
import { roomStore} from '../../stores/room';
import {useRoomState, useWhiteboardState} from '../../containers/root-container';
import {RoomMessage} from "../../utils/agora-rtm-client";
import {globalStore} from "../../stores/global";

export default function OneToOne() {
  const {
    value,
    messages,
    sendMessage,
    handleChange,    
  } = useChatText();

    const playerRef :React.RefObject<HTMLVideoElement> = React.createRef();

  const [ysUrl, setYsUrl] = useState("");
    const [sysUrl, setSysUrl] = useState("");

  const {teacher, students, onPlayerClick} = useStream();
    const roomState = useRoomState();

  console.log("whiteboardState",roomStore)
    useEffect(() => {
        console.log("changeFull",roomStore.state.studentFullScreen)

        let studentVideoDom :any= document.getElementById("studentPlayer");
        if(roomState.me.role === "student" && studentVideoDom){
            if (studentVideoDom.mozRequestFullScreen) {
                studentVideoDom.mozRequestFullScreen();
            } else if (studentVideoDom.webkitRequestFullscreen) {
                studentVideoDom.webkitRequestFullscreen();
            } else if (studentVideoDom.msRequestFullscreen) {
                studentVideoDom.msRequestFullscreen();
            }
        }
    },[roomStore.state.studentFullScreen])
    useEffect(() => {
        console.log("changeteacherFullScreen",roomStore.state.teacherFullScreen)
        let hideBtnDom :any= document.getElementById("hideBtn");
        hideBtnDom.click();

        let teacherVideoDom :any = document.getElementById("myPlayer");
        if(roomState.me.role === "student" && teacherVideoDom){
            if(teacherVideoDom.requestFullScreen) {
                teacherVideoDom.requestFullScreen();
            } else if(teacherVideoDom.mozRequestFullScreen) {
                teacherVideoDom.mozRequestFullScreen();
            } else if(teacherVideoDom.webkitRequestFullScreen) {
                teacherVideoDom.webkitRequestFullScreen();
            }
        }
    },[roomStore.state.teacherFullScreen])
    useEffect(() => {
        let userInfo = GlobalStorage.getUserInfo().userInfo;
        let resultData = ErpApi.getToken();
        resultData.then(function(data){
            let token = JSON.parse(data.data)

            let teacherResultData = ErpApi.getVideoUrl(token.data.accessToken,userInfo.teacherDevice);
            teacherResultData.then(function(res){
                console.log("resData",res);
                if(res.code == "200") {
                    var hlsHdUrl = res.data[0].hlsHd;
                    if(res.data[0].hlsHd) {
                        if (res.data[0].hlsHd.indexOf("https") < 0) {
                            hlsHdUrl = res.data[0].hlsHd.replace("http", "https");
                        }
                    }
                    setYsUrl(hlsHdUrl);
                    var player = new EZUIKit.EZUIPlayer('myPlayer');

                    const myPlayerflashId = document.getElementById("myPlayer");
                    if(myPlayerflashId) {
                        myPlayerflashId.addEventListener("play", (event: any) => {
                            console.log("play11")
                        });
                        myPlayerflashId.addEventListener("pause", (event: any) => {
                            console.log("stop11")
                        });
                        myPlayerflashId.addEventListener("fullscreenchange", (event: any) => {
                            console.log("fullscreenchange111")
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.teacherFullScreen?RoomMessage.teacherExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateTeacherFullScreen(!roomStore.state.teacherFullScreen);
                                        })
                                }
                            }
                        });
                        myPlayerflashId.addEventListener("mozfullscreenchange", function(e) {
                            console.log("mozfullscreenchange ", e);
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.teacherFullScreen?RoomMessage.teacherExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateTeacherFullScreen(!roomStore.state.teacherFullScreen);
                                        })
                                }
                            }
                        });
                        myPlayerflashId.addEventListener("webkitfullscreenchange", function(e) {
                            console.log("webkitfullscreenchange", e);
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.teacherFullScreen?RoomMessage.teacherExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateTeacherFullScreen(!roomStore.state.teacherFullScreen);
                                        })
                                }
                            }
                        });
                        myPlayerflashId.addEventListener("msfullscreenchange", function(e) {
                            console.log("msfullscreenchange", e);
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.teacherFullScreen?RoomMessage.teacherExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateTeacherFullScreen(!roomStore.state.teacherFullScreen);
                                        })
                                }
                            }
                        });
                    }
                }
            })

            let studentResultData = ErpApi.getVideoUrl(token.data.accessToken,userInfo.studentDevice);
            studentResultData.then(function(res){
                if(res.code == "200") {
                    var hlsHdUrl = res.data[0].hlsHd;
                    if(res.data[0].hlsHd) {
                        if (res.data[0].hlsHd.indexOf("https") < 0) {
                            hlsHdUrl = res.data[0].hlsHd.replace("http", "https");
                        }
                    }
                    setSysUrl(hlsHdUrl)
                    var studentPlayer = new EZUIKit.EZUIPlayer('studentPlayer');

                    const studentPlayerflashId = document.getElementById("studentPlayer");
                    if(studentPlayerflashId) {
                        studentPlayerflashId.addEventListener("play", (event: any) => {
                            console.log("play11")
                        });
                        studentPlayerflashId.addEventListener("pause", (event: any) => {
                            console.log("stop11")
                        });
                        studentPlayerflashId.addEventListener("fullscreenchange", (event: any) => {
                            console.log("fullscreenchange111")
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.studentFullScreen?RoomMessage.studentExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateStudentFullScreen(!roomStore.state.studentFullScreen);
                                        })
                                }
                            }
                        });
                        studentPlayerflashId.addEventListener("mozfullscreenchange", function(e) {
                            console.log("mozfullscreenchange ", e);
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.studentFullScreen?RoomMessage.studentExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateStudentFullScreen(!roomStore.state.studentFullScreen);
                                        })
                                }
                            }
                        });
                        studentPlayerflashId.addEventListener("webkitfullscreenchange", function(e) {
                            console.log("webkitfullscreenchange", e);
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.studentFullScreen?RoomMessage.studentExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateStudentFullScreen(!roomStore.state.studentFullScreen);
                                        })
                                }
                            }
                        });
                        studentPlayerflashId.addEventListener("msfullscreenchange", function(e) {
                            console.log("msfullscreenchange", e);
                            if(roomState.me.role === "teacher") {
                                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                                        {cmd: roomStore.state.studentFullScreen?RoomMessage.studentExit:RoomMessage.teacherFull})
                                        .then((result: any) => {
                                            console.log("peerMessage result ", result);
                                        })
                                        .catch(console.warn)
                                        .finally(() => {
                                            console.log(123123)
                                            roomStore.updateStudentFullScreen(!roomStore.state.studentFullScreen);
                                        })
                                }
                            }
                        });
                    }
                }
            })
        })
    },[])

  return (
    <div className="room-container">
        <div id='hideBtn' style={{display: "none"}} onClick={() => {
            console.log("clickHideBtn")
            let teacherVideoDom :any = document.getElementById("myPlayer");
            if(roomState.me.role === "student" && teacherVideoDom){
                if(teacherVideoDom.requestFullScreen) {
                    teacherVideoDom.requestFullScreen();
                } else if(teacherVideoDom.mozRequestFullScreen) {
                    teacherVideoDom.mozRequestFullScreen();
                } else if(teacherVideoDom.webkitRequestFullScreen) {
                    teacherVideoDom.webkitRequestFullScreen();
                }
            }
        }}></div>
      <MediaBoard />
      <div className="live-board large-live-board" style={{justifyContent: "flex-start"}}>
        <div className="video-board" style={{height: "100%"}}>
            <div onClick={() => {
                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                        {cmd: RoomMessage.teacherChange})
                        .then((result: any) => {
                            console.log("peerMessage result ", result);
                        })
                        .catch(console.warn)
                        .finally(() => {
                            let teacherYSDom: any = document.getElementById("myPlayer");
                            let teacherVideoDom: any = document.getElementById("teacherVideo");
                            if (teacherYSDom.className == "teacherVideo") {
                                teacherYSDom.className = "";
                                teacherVideoDom.className = "teacherVideo";
                            } else {
                                teacherYSDom.className = "teacherVideo";
                                teacherVideoDom.className = "teacherLargeVideo";
                            }
                        })
                }
            }}>
                <video
                    id="myPlayer"
                    autoPlay
                    muted
                    width="512"
                    height="300"
                    controls={roomState.me.role=="teacher"?true:false}
                    playsInline
                    webkit-playsinline
                >
                    <source src={ysUrl} type="" />
                </video>
            </div>
            <div id="teacherVideo" className="teacherVideo" onClick={() => {
                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                        {cmd: RoomMessage.teacherChange})
                        .then((result: any) => {
                            console.log("peerMessage result ", result);
                        })
                        .catch(console.warn)
                        .finally(() => {
                            let teacherYSDom: any = document.getElementById("myPlayer");
                            let teacherVideoDom: any = document.getElementById("teacherVideo");
                            if (teacherYSDom.className == "teacherVideo") {
                                teacherYSDom.className = "";
                                teacherVideoDom.className = "teacherVideo";
                            } else {
                                teacherYSDom.className = "teacherVideo";
                                teacherVideoDom.className = "teacherLargeVideo";
                            }
                        })
                }
            }}>
              {teacher ?
                <VideoPlayer
                  role="teacher"
                  streamID={teacher.streamID}
                  stream={teacher.stream}
                  domId={`${teacher.streamID}`}
                  id={`${teacher.streamID}`}
                  account={teacher.account}
                  handleClick={onPlayerClick}
                  video={teacher.video}
                  audio={teacher.audio}
                  local={teacher.local}
                  /> :
                <VideoPlayer
                  role="teacher"
                  account={'teacher'}
                  domId={'teacher'}
                  streamID={0}
                  video
                  audio
                  />}
            </div>
            <div onClick={() => {
                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                        {cmd: RoomMessage.studentChange})
                        .then((result: any) => {
                            console.log("peerMessage result ", result);
                        })
                        .catch(console.warn)
                        .finally(() => {
                            let studentYSDom: any = document.getElementById("studentPlayer");
                            let studentVideoDom: any = document.getElementById("studentVideo");
                            if (studentYSDom.className == "studentVideo1") {
                                studentYSDom.className = "";
                                studentVideoDom.className = "studentVideo";
                            } else {
                                studentYSDom.className = "studentVideo1";
                                studentVideoDom.className = "studentLargeVideo";
                            }
                        })
                }
            }}>
                <video
                    id="studentPlayer"
                    autoPlay
                    muted={true}
                    src={sysUrl}
                    width="512"
                    height="300"
                    controls={roomState.me.role=="teacher"?true:false}
                    playsInline
                    webkit-playsinline
                >
                </video>
            </div>
            <div id="studentVideo" className="studentVideo" onClick={() => {
                if(roomStore.state.studentList && roomStore.state.studentList.length > 0) {
                    roomStore.rtmClient.sendPeerMessage(roomStore.state.studentList[0].uid,
                        {cmd: RoomMessage.studentChange})
                        .then((result: any) => {
                            console.log("peerMessage result ", result);
                        })
                        .catch(console.warn)
                        .finally(() => {
                            let studentYSDom: any = document.getElementById("studentPlayer");
                            let studentVideoDom: any = document.getElementById("studentVideo");
                            if (studentYSDom.className == "studentVideo1") {
                                studentYSDom.className = "";
                                studentVideoDom.className = "studentVideo";
                            } else {
                                studentYSDom.className = "studentVideo1";
                                studentVideoDom.className = "studentLargeVideo";
                            }
                        })
                }
            }}>
              {students[0] ?
                <VideoPlayer
                  role="student"
                  streamID={students[0].streamID}
                  stream={students[0].stream}
                  domId={`${students[0].streamID}`}
                  id={`${students[0].streamID}`}
                  handleClick={onPlayerClick}
                  account={students[0].account}
                  video={students[0].video}
                  audio={students[0].audio}
                  local={students[0].local}
                /> :
                <VideoPlayer
                  role="student"
                  account={"student"}
                  domId={"student"}
                  streamID={0}
                  video={false}
                  audio={false}
                />}
            </div>
        </div>
      </div>
    </div>
  )
}