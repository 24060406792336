import React, { useState, useEffect, useRef } from 'react';
import { Theme, FormControl } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../components/custom-button';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import LangSelect from '../components/lang-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import {useHistory} from 'react-router-dom';
import { roomStore } from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore, roomTypes } from '../stores/global';
import { t } from '../i18n';
import GlobalStorage from '../utils/custom-storage';
import { useLocation } from 'react-router';

function HomePage() {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const courseWareId: string = searchParams.get('courseWareId') as string;
  const teacherId: string = searchParams.get('teacherId') as string;
  const type: string = searchParams.get('type') as string;

  const newPayload = {
    uid: genUid(),
    rid: `2${MD5(teacherId+"000")}`,
    role: "teacher",
    roomName: teacherId,
    roomType: 2,
    video: 0,
    audio: 0,
    chat: 0,
    account: teacherId,
    rtmToken: '',
    boardId: '',
    linkId: 0,
    sharedId: 0,
    lockBoard: 1,
    grantBoard: 0,
  };
  roomStore.loginAndJoin(newPayload).then(() => {
    history.push(`/classroom/upload?courseWareId=${courseWareId}&type=${type}`);
  }).catch((err: any) => {
    if (err.reason) {
      globalStore.showToast({
        type: 'rtmClient',
        message: t('toast.rtm_login_failed_reason', {reason: err.reason}),
      })
    } else {
      globalStore.showToast({
        type: 'rtmClient',
        message: t('toast.rtm_login_failed'),
      })
    }
    history.push(`/uploadLogin?courseWareId=${courseWareId}&type=${type}`);
    console.warn(err);
  })
      .finally(() => {

      })

  return (
    <div>

    </div>
  )
}
export default React.memo(HomePage);