import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
export default function ({data, icon, disable, className, onClick, active, applyIndex, applyValue}: any) {
  let iconClass = disable ? '' : (icon ? 'icon-btn' : 'icon');
  iconClass = active ? iconClass + " active" : iconClass;

  const dataName = data ? data : ''
  return (
    applyIndex >= 0?
      <Tooltip title={applyValue} placement="top">
        <div className={`${iconClass} ${className}`} onClick={onClick} data-name={dataName}></div>
        </Tooltip>:
      <div className={`${iconClass} ${className}`} onClick={onClick} data-name={dataName}></div>
  )
}