import React from 'react';
import ChatPanel from './panel';
import { ChatMessage } from '../../utils/types';
import StudentList from '../big-student-list';
import { List } from 'immutable';
import { t } from '../../i18n';
import { useGlobalState } from '../../containers/root-container';
import { globalStore } from '../../stores/global';

interface ChatBoard {
  name?: string
  messages: List<ChatMessage>
  value: string
  teacher?: boolean
  studentList: any[]
  mute?: boolean
  roomCount?: number
  sendMessage: (evt: any) => void
  handleChange: (evt: any) => void
}

export default function ChatBoard (props: ChatBoard) {
  const {active} = useGlobalState();
  const count = active !== 'chatroom' ? globalStore.state.newMessageCount : 0;

  return (
    <div className="chat-board">
      {props.name ? <div className="chat-roomname">{props.name}{props.roomCount ? `(${props.roomCount-1})` : null }</div> : null}
      {props.teacher && location.href.indexOf("big-class") > -1?
          <div className={`small-class chat-board`}>
            <div className="menu">
              <div onClick={() => { globalStore.setActive('chatroom') }}
                   className={`item ${active === 'chatroom' ? 'active' : ''}`}>
                {t('room.chat_room')}
                {active !== 'chatroom' && count > 0 ?
                    <span className={`message-count ${globalStore.state.language}`}>{count}</span> : null}
              </div>
              {props.teacher ?<div onClick={() => { globalStore.setActive('studentList') }}
                                        className={`item ${active === 'studentList' ? 'active' : ''}`}>{t('room.student_list')}</div>: null}
            </div>
            <div className={`chat-container ${active === 'chatroom' ? '' : 'hide'}`}>
              <ChatPanel
                  messages={props.messages}
                  value={props.value}
                  sendMessage={props.sendMessage}
                  handleChange={props.handleChange}/>
            </div>
            <div className={`student-container ${active !== 'chatroom' ? '' : 'hide'}`}>
              <StudentList
                  role={'student'}
                  list={props.studentList}
              />
            </div>
          </div>:
          <ChatPanel
              messages={props.messages}
              value={props.value}
              sendMessage={props.sendMessage}
              handleChange={props.handleChange}/>
      }
    </div>
  )
}