/**
 * Created by doron on 18/3/2020.
 */
import React, { useState, useEffect, useRef } from 'react';
import { Theme, FormControl, Grid, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../components/custom-button';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import LangSelect from '../components/lang-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import {useHistory} from 'react-router-dom';
import { roomStore } from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore, roomTypes } from '../stores/global';
import { t } from '../i18n';
import GlobalStorage from '../utils/custom-storage';

import {ErpApi} from '../utils/erpApi'

const currentDate = new Date();

type SearchDataInfo = {
    date: string
}

const defaultSearchData: SearchDataInfo = {
    date: ""
};

function ClassListPage() {
    let searchData = defaultSearchData;
    const session = GlobalStorage.getUserInfo().userInfo;
    const history = useHistory();

    // const [totalPage,setTotalPage] = useState(0);
    const [dateChanged,setDateChanged] = useState(false);
    const [dateIndex,setDateIndex] = useState(currentDate.getDay());
    const [typeIndex,setTypeIndex] = useState(0);

    const formatDate = function(date:any){
        let cd = new Date(date);
        let year = cd.getFullYear();
        let month = cd.getMonth()+1;
        let day = cd.getDate();
        return year+"-"+(month<10?"0"+month:month)+"-"+(day<10?"0"+day:day);
    };
    const dateList = [{
        id: 0,
        name: "周日",
        date: formatDate(new Date().setDate(currentDate.getDate()+0-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+0-currentDate.getDay()))).getDate()
    },{
        id: 1,
        name: "周一",
        date: formatDate(new Date().setDate(currentDate.getDate()+1-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+1-currentDate.getDay()))).getDate()
    },{
        id: 2,
        name: "周二",
        date: formatDate(new Date().setDate(currentDate.getDate()+2-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+2-currentDate.getDay()))).getDate()
    },{
        id: 3,
        name: "周三",
        date: formatDate(new Date().setDate(currentDate.getDate()+3-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+3-currentDate.getDay()))).getDate()
    },{
        id: 4,
        name: "周四",
        date: formatDate(new Date().setDate(currentDate.getDate()+4-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+4-currentDate.getDay()))).getDate()
    },{
        id: 5,
        name: "周五",
        date: formatDate(new Date().setDate(currentDate.getDate()+5-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+5-currentDate.getDay()))).getDate()
    },{
        id: 6,
        name: "周六",
        date: formatDate(new Date().setDate(currentDate.getDate()+6-currentDate.getDay())),
        dateValue: (new Date(new Date().setDate(currentDate.getDate()+6-currentDate.getDay()))).getDate()
    }];

    const classType = [{
        id: 0,
        urlName: "",
        name: "小班课",
        classTypeIndex: 1
    },{
        id: 1,
        urlName: "/single",
        name: "一对一",
        classTypeIndex: 0
    },{
        id: 2,
        urlName: "/large",
        name: "大班课",
        classTypeIndex: 2
    }];

    const [classListData, setClassListData] = useState([{
        id: 1,
        name: "",
        scheduleList: [{
            startTime: "",
            endTime: ""
        }]
    }]);

    const getListData = function(searchInfo: SearchDataInfo,index: number){
        let resultData = ErpApi.getClassList(searchInfo,classType[index].urlName);
        resultData.then(function(data){
            if(data.status == 0) {
                setDateChanged(true);
                // setTotalPage(data.data.totalPages);
                setClassListData(data.data);
            }else if(data.status == 401){
                history.push(`/teacher`);
            }else{
                setDateChanged(true);
                globalStore.showToast({
                    type: 'notice-board',
                    message: data.message
                });
            }
        });
    };

    if(!dateChanged) {
        searchData.date = formatDate(currentDate);
        getListData(searchData,typeIndex);
    }

    const clickPaper: any = (value: any) => {
        roomStore.exitAll()
            .then(() => {
            })
            .catch(console.warn)
            .finally(() => {
                let resultData = ErpApi.classBegin(value.id);
                resultData.then(function(data){
                    if(data.status == 0) {
                        let userInfo = GlobalStorage.getUserInfo().userInfo;
                        userInfo.banId = value.id;
                        if(value.deviceNumberYsList) {
                            for (var i = 0; i < value.deviceNumberYsList.length; i++) {
                                if (value.deviceNumberYsList[i].userType == "STAFF") {
                                    userInfo.teacherDevice = value.deviceNumberYsList[i].deviceNumber;
                                } else if (value.deviceNumberYsList[i].userType == "STUDENT") {
                                    userInfo.studentDevice = value.deviceNumberYsList[i].deviceNumber;
                                }
                            }
                        }

                        GlobalStorage.save('userInfo', userInfo);
                        const path = roomTypes[classType[typeIndex].classTypeIndex].path;
                        const payload = {
                            uid: userInfo.teacherId+"",
                            rid: `${classType[typeIndex].classTypeIndex}${MD5(value.id+"")}`,
                            role: session.role,
                            roomName: value.name,
                            roomType: classType[typeIndex].classTypeIndex,
                            video: 1,
                            audio: 1,
                            chat: 1,
                            account: session.teacherName,
                            rtmToken: '',
                            boardId: '',
                            linkId: 0,
                            sharedId: 0,
                            lockBoard: 1,
                            grantBoard: 0
                        }
                        globalStore.showLoading();
                        roomStore.loginAndJoin(payload).then(() => {
                            history.push(`/classroom/${path}`);
                        }).catch((err: any) => {
                            if (err.reason) {
                                globalStore.showToast({
                                    type: 'rtmClient',
                                    message: t('toast.rtm_login_failed_reason', {reason: err.reason}),
                                })
                            } else {
                                globalStore.showToast({
                                    type: 'rtmClient',
                                    message: t('toast.rtm_login_failed'),
                                })
                            }
                            console.warn(err);
                        }).finally(() => {
                            globalStore.stopLoading();
                        })
                    }else if(data.status == 401){
                        history.push(`/teacher`);
                    }else{
                        setDateChanged(true);
                        globalStore.showToast({
                            type: 'notice-board',
                            message: data.message
                        });
                    }
                });
            });
    };

    const clickDate = function(value:any){
        setDateIndex(value.id);
        searchData.date = value.date;
        getListData(searchData,typeIndex);
    };

    const clickType = function(value:any){
        setTypeIndex(value.id);
        getListData(searchData,value.id);
    };

    const logout = function() {
        let resultData = ErpApi.doLogout();
        resultData.then(function(data){
            history.push(`/teacher`);
        });
    };

    return (
        <div className="classList">
            <div className="classHeader">
                <div className="logoImageDiv"><div className="logoImage"></div></div>
                <div className="logoTitle">课程表</div>
                <div className="logoRight">
                    <Icon className="icon-exit logoRightIcon" onClick={(evt: any) => {
                                logout();
                            }} />
                </div>
            </div>
            <Paper>
                <div className="category">
                    <div className="categoryLabel">
                        <div>{dateList[dateIndex].date}</div>
                        <div></div>
                    </div>
                    {dateList.map(value => {
                        return(
                            <div className="categoryList" onClick={function(){
                                clickDate(value);
                            }}>
                                <div className="dateTitle">{value.name}</div>
                                <div className={`dateValue ${dateIndex==value.id?'selectDateValue':''}`}>{value.dateValue}</div>
                            </div>
                        )
                    })}
                    <div className="clearFloat"></div>
                </div>
            </Paper>
            <div className="typeList">
                {classType.map(value => {
                    return(
                        <div className={`typeCategoryList ${typeIndex==value.id?'selectTypeCategory':''}`} onClick={function(){
                                clickType(value);
                            }}>
                            <div>{value.name}</div>
                        </div>
                    )
                })}
                <div className="clearFloat"></div>
            </div>
            <Grid container spacing={3} className="gridList">
                {classListData.map(value => {
                    return (
                        <Grid key={value.id} item xs={3}>
                            <Paper className={'custom-paper'} onClick={function(){
                                clickPaper(value);
                            }}>
                                <div className="className">{value.name}</div>
                                <div className="classDate">{value.scheduleList[0].startTime}--{value.scheduleList[0].endTime}</div>
                                <div className="classBtn">开始上课</div>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}
export default React.memo(ClassListPage);