import React, {useState, useRef, useEffect} from 'react';
import VideoPlayer from '../../components/video-player';

import './big-class.scss';
import ChatBoard from '../../components/chat/board';
import MediaBoard from '../../components/uploadMediaboard';
import useStream from '../../hooks/use-streams';
import useChatText from '../../hooks/use-chat-text';
import { RoomMessage } from '../../utils/agora-rtm-client';
import { AgoraElectronClient } from '../../utils/agora-electron-client';
import AgoraWebClient from '../../utils/agora-rtc-client';
import { useRoomState } from '../../containers/root-container';
import { roomStore } from '../../stores/room';
import { platform } from '../../utils/platform';
import { useLocation } from 'react-router';
import {useHistory} from 'react-router-dom';
import Button from '../../components/custom-button';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { t } from '../../i18n';
import { globalStore } from '../../stores/global';
import {ErpApi} from '../../utils/erpApi'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

type FolderInfo = {
  folderId: string
  name: string
}
const defaultFolderId: FolderInfo = {
  folderId: '',
  name: ''
}

export default function UploadFile() {
  const [folderInfo, setFolderInfo] = useState<FolderInfo>(defaultFolderId);
  const [currentFolderInfo, setCurrentFolderInfo] = useState<any>({id: '',name: ''});
  const [folderList, setFolderList] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalNum, setTotalNum] = useState(0);

  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseWareId: string = searchParams.get('courseWareId') as string;
  const type: string = searchParams.get('type') as string;

  const roomState = useRoomState();

  const me = roomState.me;

  const memberCount = roomState.rtm.memberCount;

  const {teacher, currentHost, onPlayerClick} = useStream();

  const rtmLock = useRef<boolean>(false);
  const lock = useRef<boolean>(false);

  useEffect(() => {
    console.log(22222222)
    setFolderInfo({
      folderId: courseWareId,
      name: ''
    })
    getFolderList(0,rowsPerPage);

    rtmLock.current = false;
    return () => {
      rtmLock.current = true;
      lock.current = true;
    }
  }, []);

  const getFolderList = function(page:number,rowsPerPage: number){
    let resultData = ErpApi.getTeacherFolderList(page,rowsPerPage);
    resultData.then(function(data){
      if(data.status == 0) {
        setTotalNum(data.data.totalElements);
        setFolderList(data.data.content);

        if(courseWareId){
          for(var i=0;i<data.data.content.length;i++){
            if(parseInt(courseWareId) == data.data.content[i].id){
              setFolderInfo({
                folderId: data.data.content[i].id,
                name: data.data.content[i].name
              })
            }
          }
        }
      }else if(data.status == 401){
        history.push(`/uploadLogin?courseWareId=${courseWareId}&type=${type}`);
      }else{
        globalStore.showToast({
          type: 'notice-board',
          message: data.message
        });
      }
    });
  };

  const handleClick = (type: string) => {
    if (rtmLock.current) return;

    if (type === 'hands_up') {
      if (roomStore.state.course.teacherId) {
        rtmLock.current = true;
        roomStore.rtmClient.sendPeerMessage(roomStore.state.course.teacherId,
            {cmd: RoomMessage.applyCoVideo})
            .then((result: any) => {
              console.log("peerMessage result ", result);
            })
            .catch(console.warn)
            .finally(() => {
              rtmLock.current = false;
            })
      }
    }

    if (type === 'hands_up_end') {
      if (roomStore.state.course.teacherId) {
        rtmLock.current = true;
        roomStore.rtmClient.sendPeerMessage(roomStore.state.course.teacherId,
            {cmd: RoomMessage.cancelCoVideo})
            .then((result: any) => {
              console.log("peerMessage result ", result);
            })
            .catch(console.warn)
            .finally(() => {
              rtmLock.current = false;
            })
      }
    }
  }

  // TODO: handleClose
  const closeLock = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      closeLock.current = true;
    }
  }, []);

  const handleClose = (type: string, streamID: number) => {
    if (type === 'close') {
      if (!currentHost || closeLock.current) return;

      const rtmClient = roomStore.rtmClient;
      const rtcClient = roomStore.rtcClient;
      const teacherUid = roomStore.state.course.teacherId;

      console.log("close rtmClient: ", rtmClient, ", rtcClient: ", rtcClient, ", teacherUid: ", teacherUid, ", lock :", closeLock.current);

      if (currentHost.streamID === +me.uid && teacherUid) {
        const quitClient = new Promise((resolve, reject) => {
          if (platform === 'electron') {
            const nativeClient = rtcClient as AgoraElectronClient;
            const val = nativeClient.unpublish();
            if (val >= 0) {
              resolve();
            } else {
              console.warn('quit native client failed');
              reject(val);
            }
          }
          if (platform === 'web') {
            const webClient = rtcClient as AgoraWebClient;
            resolve(webClient.unpublishLocalStream());
          }
        });
        closeLock.current = true;
        rtmLock.current = true;
        Promise.all([
          rtmClient.sendPeerMessage(`${teacherUid}`,{
            cmd: RoomMessage.cancelCoVideo
          }),
          quitClient
        ]).then(() => {
          rtmLock.current = false;
        }).catch((err: any) => {
          rtmLock.current = false;
          console.warn(err);
          throw err;
        }).finally(() => {
          closeLock.current = false;
        })
      }

      if (teacherUid && teacherUid === me.uid) {
        rtmLock.current = true;
        closeLock.current = true;
        Promise.all([
          rtmClient.sendPeerMessage(`${streamID}`, {
            cmd: RoomMessage.cancelCoVideo,
          }),
          roomStore.updateCourseLinkUid(0)
        ]).then(() => {
          rtmLock.current = false;
        }).catch((err: any) => {
          rtmLock.current = false;
          console.warn(err);
        }).finally(() => {
          closeLock.current = false;
        })
      }
    }
  }

  const showFolderList = function() {
    setOpen(true);
  };
  const handleCloseDialog = (value: string) => {
    setOpen(false);
  };
  const doSubmit = function() {
    setFolderInfo({
      folderId: currentFolderInfo.id,
      name: currentFolderInfo.name
    })
    setOpen(false);
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    getFolderList(newPage,rowsPerPage);
  };

  const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getFolderList(0,parseInt(event.target.value, 10));
  };

  return (
      <div className="room-container">
        <div className="live-container">
          <MediaBoard
              handleClick={handleClick}
              folderId={parseInt(folderInfo.folderId)}
              courseWareType={type}
          >
            <div className="video-container">
              {currentHost ?
                  <VideoPlayer
                      role="teacher"
                      streamID={currentHost.streamID}
                      stream={currentHost.stream}
                      domId={`${currentHost.streamID}`}
                      id={`${currentHost.streamID}`}
                      account={currentHost.account}
                      handleClick={onPlayerClick}
                      close={me.role === 'teacher' || +me.uid === currentHost.streamID}
                      handleClose={handleClose}
                      video={currentHost.video}
                      audio={currentHost.audio}
                      local={currentHost.local}
                  /> :
                  null
              }
            </div>
          </MediaBoard>
        </div>
      </div>
  )
}