import React, { useState, useEffect, useRef } from 'react';
import { Theme, FormControl } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../components/custom-button';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import LangSelect from '../components/lang-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import {useHistory} from 'react-router-dom';
import { roomStore } from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore, roomTypes } from '../stores/global';
import { t } from '../i18n';
import GlobalStorage from '../utils/custom-storage';

import {ErpApi} from '../utils/erpApi'

const useStyles = makeStyles ((theme: Theme) => ({
  formControl: {
    minWidth: '240px',
    maxWidth: '240px',
  }
}));

type SessionInfo = {
  roomName: string
  roomType: number
  yourName: string
  role: string
  teacherName: string
  banId: string
  teacherId: string
  teacherDevice: string
  studentDevice: string
}

const defaultState: SessionInfo = {
  roomName: '',
  roomType: 0,
  role: 'teacher',
  yourName: '',
  teacherName: '',
  banId: '',
  teacherId: '',
  teacherDevice: '',
  studentDevice: ''
}

function HomePage() {
  const classes = useStyles();

  const history = useHistory();

  const handleSetting = (evt: any) => {
    history.push({pathname: `/device_test`});
  }

  const {
    HomeBtn,
      SettingBtn
  } = usePlatform();

  const ref = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    }
  }, []);

  let defaultUserInfo = defaultState;
  if(GlobalStorage.getUserInfo().userInfo){
    defaultUserInfo = GlobalStorage.getUserInfo().userInfo;
    defaultUserInfo.role = "teacher";
  }
  const [session, setSessionInfo] = useState<SessionInfo>(defaultUserInfo);

  const [required, setRequired] = useState<any>({} as any);

  const handleSubmit = () => {
    if (!session.roomName) {
      setRequired({...required, roomName: "请输入用户名"});
      return;
    }

    if (!session.yourName) {
      setRequired({...required, yourName: "请输入密码"});
      return;
    }

    let resultData = ErpApi.doLogin(session.roomName,session.yourName,"STAFF");
    resultData.then(function(data){
      if(data.status == 0) {
        let userInfo = session;
        userInfo.teacherName = data.data.staff.name;
        userInfo.teacherId = data.data.staff.id;
        GlobalStorage.save('userInfo', userInfo);
        history.push(`/classList`);
      }else{
        globalStore.showToast({
          type: 'notice-board',
          message: "账号或者密码错误"
        });
      }
    });
  }

  return (
    <div className={`flex-container ${isElectron ? 'draggable' : 'home-cover-web' }`}>
      {isElectron ? null :
          <div className="web-menu">
            <div className="web-menu-container">
              <div className="setting-container">
                <Icon className="icon-setting" onClick={handleSetting}/>
              </div>
            </div>
          </div>
      }
      <div className="custom-card">
        <div className="icon-container">
          <SettingBtn />
        </div>
        <div className="flex-item card">
          <div className="position-content flex-direction-column">
            <FormControl className={classes.formControl}>
              <div className="bigTitle">Hello！</div>
              <div className="smallTitle">
                欢迎登录
                <span className="smallRedTitle">多艺网校直播系统</span>
              </div>
            </FormControl>
            <FormControl className={`${classes.formControl} formText`}>
              <FormInput Label={"用户名"} value={session.roomName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    roomName: val
                  });
                }}
                requiredText={required.roomName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormInput type="password" Label={"密码"} value={session.yourName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    yourName: val
                  });
                }}
                requiredText={required.yourName}
              />
            </FormControl>
            <Button className="loginBtn" name={"登录"} onClick={handleSubmit}/>
          </div>
        </div>
        <div className="flex-item cover">
          <div className={`cover-placeholder-web ${t('home.cover_class')}`}></div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(HomePage);