import React, { useState, useEffect, useRef } from 'react';
import { Theme, FormControl } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../components/custom-button';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import LangSelect from '../components/lang-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import {useHistory} from 'react-router-dom';
import { roomStore } from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore, roomTypes } from '../stores/global';
import { t } from '../i18n';
import GlobalStorage from '../utils/custom-storage';
import { useLocation } from 'react-router';

import {ErpApi} from '../utils/erpApi'

const useStyles = makeStyles ((theme: Theme) => ({
  formControl: {
    minWidth: '240px',
    maxWidth: '240px',
  }
}));

type SessionInfo = {
  roomName: string
  roomType: number
  yourName: string
  role: string
  teacherName: string
  banId: string
}

const defaultState: SessionInfo = {
  roomName: '',
  roomType: 0,
  role: 'teacher',
  yourName: '',
  teacherName: '',
  banId: ''
}

function HomePage() {
  const classes = useStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseWareId: string = searchParams.get('courseWareId') as string;
  const type: string = searchParams.get('type') as string;

  console.log("login courseWareId");
  console.log(courseWareId);

  const history = useHistory();

  const {
    HomeBtn
  } = usePlatform();

  const ref = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    }
  }, []);

  let defaultUserInfo = defaultState;

  console.log("GlobalStorage")
  console.log(GlobalStorage.getUserInfo().userInfo)
  if(GlobalStorage.getUserInfo().userInfo){
    defaultUserInfo = GlobalStorage.getUserInfo().userInfo;
    defaultUserInfo.role = "teacher";
  }
  const [session, setSessionInfo] = useState<SessionInfo>(defaultUserInfo);

  const [required, setRequired] = useState<any>({} as any);

  const handleSubmit = () => {
    if (!session.roomName) {
      setRequired({...required, roomName: "请输入用户名"});
      return;
    }

    if (!session.yourName) {
      setRequired({...required, yourName: "请输入密码"});
      return;
    }

    roomStore.exitAll()
        .then(() => {
        })
        .catch(console.warn)
        .finally(() => {
          let resultData = ErpApi.doLogin(session.roomName, session.yourName, "STAFF");
          resultData.then(function (data) {
            if (data.status == 0) {
              const payload = {
                uid: genUid(),
                rid: `2${MD5(data.data.staff.id + "000")}`,
                role: session.role,
                roomName: session.roomName,
                roomType: 2,
                video: 0,
                audio: 0,
                chat: 0,
                account: session.roomName,
                rtmToken: '',
                boardId: '',
                linkId: 0,
                sharedId: 0,
                lockBoard: 1,
                grantBoard: 0,
              };
              ref.current = true;
              globalStore.showLoading();
              console.log(data)
              roomStore.loginAndJoin(payload).then(() => {
                GlobalStorage.save('userInfo', session);
                history.push(`/classroom/upload?courseWareId=${courseWareId}&type=${type}`);
              }).catch((err: any) => {
                if (err.reason) {
                  globalStore.showToast({
                    type: 'rtmClient',
                    message: t('toast.rtm_login_failed_reason', {reason: err.reason}),
                  })
                } else {
                  globalStore.showToast({
                    type: 'rtmClient',
                    message: t('toast.rtm_login_failed'),
                  })
                }
                console.warn(err);
              })
                  .finally(() => {
                    ref.current = false;
                    globalStore.stopLoading();
                  })
            } else {
              globalStore.showToast({
                type: 'notice-board',
                message: "账号或者密码错误"
              });
            }
          });
        });
  }

  return (
    <div className={`flex-container ${isElectron ? 'draggable' : 'home-cover-web' }`}>
      <div className="custom-card upload-custom-card">
        <div className="flex-item card">
          <div className="position-content flex-direction-column">
            <FormControl className={classes.formControl}>
              <div className="bigTitle">Hello！</div>
              <div className="smallTitle">
                欢迎登录
                <span className="smallRedTitle">多艺网校课件上传</span>
              </div>
            </FormControl>
            <FormControl className={`${classes.formControl} formText`}>
              <FormInput Label={"用户名"} value={session.roomName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    roomName: val
                  });
                }}
                requiredText={required.roomName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormInput type="password" Label={"密码"} value={session.yourName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    yourName: val
                  });
                }}
                requiredText={required.yourName}
              />
            </FormControl>
            <Button className="loginBtn" name={"登录"} onClick={handleSubmit}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(HomePage);