import React, { useState, useEffect, useRef } from 'react';
import { Theme, FormControl } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Button from '../components/custom-button';
import RoleRadio from '../components/role-radio';
import Icon from '../components/icon';
import FormInput from '../components/form-input';
import FormSelect from '../components/form-select';
import LangSelect from '../components/lang-select';
import { isElectron } from '../utils/platform';
import { usePlatform } from '../containers/platform-container';
import {useHistory} from 'react-router-dom';
import { roomStore } from '../stores/room';
import { genUid } from '../utils/helper';
import MD5 from 'js-md5';
import { globalStore, roomTypes } from '../stores/global';
import { t } from '../i18n';
import GlobalStorage from '../utils/custom-storage';

import {ErpApi} from '../utils/erpApi';

const useStyles = makeStyles ((theme: Theme) => ({
  formControl: {
    minWidth: '240px',
    maxWidth: '240px',
  }
}));

type SessionInfo = {
  roomName: string
  roomType: number
  yourName: string
  phone: string
  role: string
}

const defaultState: SessionInfo = {
  roomName: '',
  roomType: 2,
  yourName: '',
  phone: '',
  role: 'student',
}

function HomePage() {
  const classes = useStyles();

  useEffect(() => {
    console.log(123123)
  });
  const userAgent = window.navigator.userAgent;

  const history = useHistory();

  const handleSetting = (evt: any) => {
    history.push({pathname: `/device_test`});
  }

  const {
    HomeBtn
  } = usePlatform();

  const ref = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    }
  }, []);

  let defaultUserInfo = defaultState;
  if(GlobalStorage.getUserInfo().userInfo){
    defaultUserInfo = GlobalStorage.getUserInfo().userInfo;
    defaultUserInfo.role = "student";
  }
  const [session, setSessionInfo] = useState<SessionInfo>(defaultUserInfo);

  const [required, setRequired] = useState<any>({} as any);

  const handleSubmit = () => {
    if (!session.roomName) {
      setRequired({...required, roomName: t('home.missing_room_name')});
      return;
    }

    if (!session.yourName) {
      setRequired({...required, yourName: t('home.missing_your_name')});
      return;
    }

    if (!session.phone) {
      setRequired({...required, phone: "请输入手机号"});
      return;
    }

    roomStore.exitAll()
    .then(() => {
      let resultData = ErpApi.doOtherLogin(session.roomName,session.phone,session.yourName);
      resultData.then(function(data){
        if(data.status == 0) {
          const path = roomTypes[2].path;
          const payload = {
            uid: session.phone,
            rid: `2${MD5(session.roomName)}`,
            role: session.role,
            roomName: session.roomName,
            roomType: 2,
            video: 1,
            audio: 1,
            chat: 1,
            account: session.yourName,
            rtmToken: '',
            boardId: '',
            linkId: 0,
            sharedId: 0,
            lockBoard: 1,
            grantBoard: 0,
          };
          ref.current = true;
          globalStore.showLoading();
          roomStore.loginAndJoin(payload).then(() => {
            GlobalStorage.save('userInfo', session);
            history.push(`/classroom/${path}`);
          }).catch((err: any) => {
            if (err.reason) {
              globalStore.showToast({
                type: 'rtmClient',
                message: t('toast.rtm_login_failed_reason', {reason: err.reason}),
              })
            } else {
              globalStore.showToast({
                type: 'rtmClient',
                message: t('toast.rtm_login_failed'),
              })
            }
            console.warn(err);
          })
              .finally(() => {
                ref.current = false;
                globalStore.stopLoading();
              })
        }else{
          globalStore.showToast({
            type: 'notice-board',
            message: "房间号不存在或者老师未开课！"
          });
        }
      });
    })
    .catch(console.warn)
    .finally(() => {

    });
  };

  return (
    <div className={`flex-container ${isElectron ? 'draggable' : 'home-cover-web' }`}>
      {isElectron ? null :
          <div className="web-menu">
            <div className="web-menu-container">
              <div className="setting-container">
                <Icon className="icon-setting" onClick={handleSetting}/>
              </div>
            </div>
          </div>
      }
      <div className="custom-card">
        <div className="flex-item card">
          <div className="position-content flex-direction-column">
            <FormControl className={classes.formControl}>
              <div className="bigTitle">Hello！</div>
              <div className="smallTitle">
                欢迎登录
                <span className="smallRedTitle">多艺网校直播系统</span>
              </div>
            </FormControl>
            <FormControl className={`${classes.formControl} formText1`}>
              <FormInput Label={"口令"} value={session.roomName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    roomName: val
                  });
                }}
                requiredText={required.roomName}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormInput Label={"手机号"} value={session.phone} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    phone: val
                  });
                }}
                         requiredText={required.phone}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormInput Label={t('home.nickname')} value={session.yourName} onChange={
                (val: string) => {
                  setSessionInfo({
                    ...session,
                    yourName: val
                  });
                }}
                requiredText={required.yourName}
              />
            </FormControl>

            <Button className="loginBtn" name={t('home.room_join')} onClick={handleSubmit}/>
          </div>
        </div>
        <div className="flex-item cover">
          {isElectron ?
              <>
              <div className={`short-title ${globalStore.state.language}`}>
                <span className="title">{t('home.short_title.title')}</span>
                <span className="subtitle">{t('home.short_title.subtitle')}</span>
              </div>
              <div className={`cover-placeholder ${t('home.cover_class')}`}></div>
              <div className='build-version'>{t("build_version")}</div>
              </>
              : <div className={`cover-placeholder-web ${t('home.cover_class')}`}></div>
          }
        </div>
      </div>
    </div>
  )
}
export default React.memo(HomePage);