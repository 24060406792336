import React from 'react';
import OSS from "ali-oss";
import uuidv4 from 'uuid/v4';
import { PPTProgressListener, UploadManager } from "../../../utils/erp-upload-manager";
import { PPTKind, Room } from "white-web-sdk";
import { ossConfig, ossClient, resolveFileInfo } from '../../../utils/helper';
import { whiteboard } from '../../../stores/whiteboard';
import { globalStore } from '../../../stores/global';
import {ErpApi} from '../../../utils/erpApi';

import FormInput from '../../../components/form-input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Paper } from '@material-ui/core';

export type UploadBtnProps = {
  folderId: number,
    courseWareType: string,
  room: Room,
  uuid: string,
  roomToken: string,
  onProgress?: PPTProgressListener,
  onFailure?: (err: any) => void,
  onSuccess?: () => void,
};

export const UploadBtn: React.FC<UploadBtnProps> = ({
    folderId,courseWareType,room, uuid, roomToken,
  onProgress, onFailure,
  onSuccess
}) => {
    const [open, setOpen] = React.useState(false);
    const [reUpload, setReUpload] = React.useState(true);
    const [courseWareName, setCourseWareName] = React.useState('');
    const [imageList, setImageList] = React.useState<any[]>([]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitImage = function(){
        if(courseWareName){
            setReUpload(true);

            let params = {
                name: courseWareName,
                coursewareType: "IMAGE",
                folderId: folderId,
                onlineCoursewareItemFormList: imageList
            }

            console.log("params>>>", params)

            let resultData = ErpApi.saveFile(params,courseWareType);
            resultData.then(function (data) {
                if (data.status == 0) {
                    console.log("data")
                    setOpen(false);
                } else {
                    globalStore.showToast({
                        type: 'notice-board',
                        message: data.message
                    });
                }
            });
        }else{
            globalStore.showToast({
                type: 'notice-board',
                message: "请输入课件名称"
            });
        }
    };

    const handleReupload = function(){
        setReUpload(false);
        setOpen(false);
    };


  const getFileType = function(type: string) {
    switch (type) {
      case 'doc':
        return "WORD";
      case 'docx':
        return "WORD";
      case 'ppt':
        return "PPT";
      case 'pptx':
        return "PPT";
      case 'pdf':
        return "PDF";
      case 'mp3':
        return "MP_THREE";
      case 'mp4':
        return "MP_FOUR";
      case 'bmp':
        return "IMAGE";
      case 'jpg':
        return "IMAGE";
      case 'png':
        return "IMAGE";
      case 'gif':
        return "IMAGE";
      case 'jepg':
        return "IMAGE";
      default:
        return "IMAGE";
    }
  };

  const uploadDynamic = async (event: any) => {
    console.log(folderId)
    if(folderId) {
      try {
        const file = event.currentTarget.files[0];
        console.log("file>>>", file)
        const fileSplit = file.name.split(".");
        const fileTypeName = getFileType(fileSplit[fileSplit.length - 1]);
        if (file) {
          const uploadManager = new UploadManager(ossClient, room);
          const pptConverter = whiteboard.client.pptConverter(roomToken);
          await uploadManager.convertFile(
              fileTypeName,
              folderId,
              courseWareType,
              file,
              pptConverter,
              PPTKind.Dynamic,
              ossConfig.folder,
              uuid,
              onProgress,
          );
          onSuccess && onSuccess();
        }
      } catch (err) {
        onFailure && onFailure(err);
        console.warn(err)
      }
    }else{
      globalStore.showToast({
        type: 'notice-board',
        message: "文件夹不存在！"
      });
    }
  }

  const uploadStatic = async (event: any) => {
    console.log(folderId)
    if(folderId) {
      try {
        const file = event.currentTarget.files[0];
        console.log("file>>>", file)
        const fileSplit = file.name.split(".");
        const fileTypeName = getFileType(fileSplit[fileSplit.length - 1]);
        if (file) {
          const uploadManager = new UploadManager(ossClient, room);
          const pptConverter = whiteboard.client.pptConverter(roomToken);
          await uploadManager.convertFile(
              fileTypeName,
              folderId,
              courseWareType,
              file,
              pptConverter,
              PPTKind.Static,
              ossConfig.folder,
              uuid,
              onProgress);
          onSuccess && onSuccess();
        }
      } catch (err) {
        onFailure && onFailure(err)
        console.warn(err)
      }
    }else{
      globalStore.showToast({
        type: 'notice-board',
        message: "文件夹不存在！"
      });
    }
  }

  const uploadImage = async (event: any) => {
      console.log(folderId)
      if(folderId) {
          try {
              console.log(event.currentTarget.files)
              const uploadFileArray: File[] = [];
              for (var i = 0; i < event.currentTarget.files.length; i++) {
                  const file = event.currentTarget.files[i];
                  if (file) {
                      uploadFileArray.push(file);
                  }
              }
              if (uploadFileArray.length > 0) {
                  const uploadManager = new UploadManager(ossClient, room);
                  const $whiteboard = document.getElementById('whiteboard') as HTMLDivElement;
                  if ($whiteboard) {
                      const {clientWidth, clientHeight} = $whiteboard;
                      const fileArray = await uploadManager.uploadImageFiles(uploadFileArray, reUpload, clientWidth / 2, clientHeight / 2, onProgress);
                      console.log("mainfileArray",fileArray)
                      setImageList(fileArray);
                      console.log("imageList?>>",imageList);

                      setOpen(true);

                  } else {
                      const clientWidth = window.innerWidth;
                      const clientHeight = window.innerHeight;
                      const fileArray = await uploadManager.uploadImageFiles(uploadFileArray, reUpload, clientWidth / 2, clientHeight / 2, onProgress);
                      console.log("mainfileArray",fileArray)
                      setImageList(fileArray);
                      console.log("imageList?>>",imageList);

                      setOpen(true);
                  }
                  onSuccess && onSuccess();
              }
          } catch (err) {
              onFailure && onFailure(err)
              console.warn(err)
          }
      }else{
          globalStore.showToast({
              type: 'notice-board',
              message: "文件夹不存在！"
          });
      }
  }

  const uploadAudioVideo = async (event: any) => {
      if(folderId) {
          const uploadManager = new UploadManager(ossClient, room);
          const file = event.currentTarget.files[0];
          if (file) {
              try {
                  const {fileName, fileType} = resolveFileInfo(file);
                  const path = `/${ossConfig.folder}`
                  const uuid = uuidv4();
                  const res = await uploadManager.addFile(`${path}/video-${fileName}${uuid}`, file,
                      onProgress
                  );
                  const isHttps = res.indexOf("https") !== -1;
                  let url : string;
                  if (isHttps) {
                      url = res;
                  } else {
                      url = res.replace("http", "https");
                  }
                  const type = fileType.split(".")[1];
                  console.log("videoUrl")
                  console.log(url)

                  let params = {
                      name: file.name,
                      coursewareType: file.name.indexOf("mp3")>-1?"MP_THREE":"MP_FOUR",
                      folderId: folderId,
                      url: url
                  }

                  console.log("videoparams>>>",params);

                  let resultData = ErpApi.saveFile(params,courseWareType);
                  resultData.then(function (data) {
                      if (data.status == 0) {
                          if (url && whiteboard.state.room) {
                              if (type.toLowerCase() === 'mp4') {
                                  const res = whiteboard.state.room.insertPlugin('video', {
                                      originX: 0,
                                      originY: 0,
                                      width: 480,
                                      height: 270,
                                      attributes: {
                                          pluginVideoUrl: url,
                                      },
                                  });
                                  console.log("[upload-btn] video resource after insert plugin, res: ", res);
                              }
                              if (type.toLowerCase() === 'mp3') {
                                  const res = whiteboard.state.room.insertPlugin('audio', {
                                      originX: 0,
                                      originY: 0,
                                      width: 480,
                                      height: 86,
                                      attributes: {
                                          pluginAudioUrl: url,
                                      },
                                  });
                                  console.log("[upload-btn] audio resource after insert plugin, res: ", res);
                              }
                              onSuccess && onSuccess();
                          }
                      } else {
                          globalStore.showToast({
                              type: 'notice-board',
                              message: data.message
                          });
                      }
                  });
              } catch (err) {
                  onFailure && onFailure(err);
              }
          }
      }else{
          globalStore.showToast({
              type: 'notice-board',
              message: "文件夹不存在！"
          });
      }
  }

  return (
    <div className="upload-btn">
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormInput Label={"输入课件名称"} value={courseWareName} onChange={
                    (val: string) => {
                      setCourseWareName(val);
                    }}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3} className="gridList">
                    {imageList.map((value,index) => {
                        return (
                            <Grid key={value.componentsCount} item xs={3}>
                                <Paper className={'custom-paper'}>
                                    <img src={value.src} width={'100px'} height={'100px'}/>
                                    <div>
                                        <TextField defaultValue={value.componentsCount} className="sortValue" label="排序" onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>) => {
                                                let currentImageList = imageList;
                                                currentImageList[index].componentsCount = parseInt(event.target.value);
                                                setImageList(currentImageList);
                                            }}/>
                                    </div>
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmitImage} color="primary">
                    确定
                </Button>
                <Button onClick={handleReupload} color="primary" autoFocus>
                    继续添加
                </Button>
            </DialogActions>
        </Dialog>
      <div className="upload-items">
        <label htmlFor="upload-image">
          <div className="upload-image-resource"></div>
          <div className="text-container">
            <div className="title">选择图片</div>
            <div className="description">bmp, jpg, png, gif</div>
          </div>
        </label>
        <input id="upload-image" accept="image/*,.bmp,.jpg,.png,.gif" multiple={true}
          onChange={uploadImage} type="file"></input>
      </div>
      <div className="slice-dash"></div>
      <div className="upload-items">
        <label htmlFor="upload-dynamic">
          <div className="upload-dynamic-resource"></div>
          <div className="text-container">
            <div className="title">文档转网页</div>
            <div className="description">只支持pptx</div>
          </div>
        </label>
        <input id="upload-dynamic" accept=".pptx" onChange={uploadDynamic} type="file"></input>
      </div>
      <div className="slice-dash"></div>
      <div className="upload-items">
        <label htmlFor="upload-static">
          <div className="upload-static-resource"></div>
          <div className="text-container">
            <div className="title">文档转图片</div>
            <div className="description">支持pptx, word, pdf</div>
          </div>
        </label>
        <input id="upload-static" accept=".doc,.docx,.ppt,.pptx,.pdf" onChange={uploadStatic} type="file"></input>
      </div>
      <div className="slice-dash"></div>
      <div className="upload-items">
        <label htmlFor="upload-video">
          <div className="upload-static-resource"></div>
          <div className="text-container">
            <div className="title">上传视频音频文件</div>
            <div className="description">支持mp4,mp3</div>
          </div>
        </label>
        <input id="upload-video" accept=".mp4,.mp3" onChange={uploadAudioVideo} type="file"></input>
      </div>
    </div>
  )
}